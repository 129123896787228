import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { projectListingFragment } from '../fragments/projects.js'
import { seoFragment } from '../fragments/seo.js'

export const getExpertiseListingPage = gql`
  ${responsiveImageFragment}
  ${seoFragment}
  query GetExpertiseListingPage($locale: SiteLocale) {
    expertiseListing(locale: $locale) {
      id
      title
      subtitle
      introText
      seo {
        ...SeoFragment
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    allExpertises(locale: $locale) {
      id
      title
      slug
      caption
      linkPreviewVideoListing
      imagePreviewListing {
        responsiveImage(
          imgixParams: { auto: format, q: "40", ar: "16:9", fit: crop }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`

export const getExpertise = gql`
  ${responsiveImageFragment}
  ${projectListingFragment}
  ${seoFragment}
  query GetExpertise($locale: SiteLocale, $filter: ExpertiseModelFilter) {
    expertise(locale: $locale, filter: $filter) {
      _allSlugLocales(fallbackLocales: it) {
        value
        locale
      }
      seo {
        ...SeoFragment
      }
      id
      title
      slug
      hero {
        id
        whiteText
        title
        text
        previewVideo
        overlayFullVideo
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      introText
      body {
        ... on GridSectionRecord {
          id
          title
          gridRows {
            id
            items {
              ... on GridBlockItemRecord {
                id
                image {
                  id
                  responsiveImage(
                    imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
                  ) {
                    ...ResponsiveImageFragment
                  }
                }
                linkVideo
                linkVideoPreview
                fullWidth
                smallHeight
                sideText
              }
              ... on GridBlockItemTextRecord {
                id
                text
                fullWidth
              }
              ... on TwoColumnsGridTextRecord {
                id
                text
                title
                titleAsSimpleText
              }
              ... on ServicesBlockRecord {
                id
                title
                texts {
                  id
                  title
                  text
                }
              }
            }
          }
        }
        ... on MediaCarouselRecord {
          id
          isVertical
          slides {
            id
            linkVideo
            linkVideoPreview
            image {
              id
              responsiveImage(
                imgixParams: { ar: "16:9", fit: crop, w: "2000", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
            portrait: image {
              id
              responsiveImage(
                imgixParams: { ar: "4:5", fit: crop, w: "2000", fm: webp }
              ) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on CtaBlockRecord {
          id
          ctaLink
          ctaLabel
          title
          subtitle
          videoUrl
          slim
        }
      }
      titleProgettiCorrelati
      bigTitleCorrelati
      _allReferencingProjects(first: 50) {
        ...ProjectListingFragment
      }
      showLogosBlock
      showAwardsBlock
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
    clientiSection(locale: $locale) {
      title
      id
      imagesList {
        url
        alt
      }
    }
    awardsSection(locale: $locale) {
      title
      id
      awards {
        id
        certificator
        brands
        title
        year
      }
    }
  }
`
