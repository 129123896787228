import gql from 'graphql-tag'

import { responsiveImageFragment } from '../fragments/images.js'
import { projectListingFragment } from '../fragments/projects.js'
import { seoFragment } from '../fragments/seo.js'

export const getHome = gql`
  ${responsiveImageFragment}
  ${projectListingFragment}
  ${seoFragment}
  query getHome($locale: SiteLocale) {
    allExpertises(locale: $locale) {
      id
      title
      slug
      caption
      linkPreviewVideoListing
      imagePreviewListing {
        responsiveImage(
          imgixParams: { ar: "16:9", fit: crop, fm: webp, w: "1000" }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
    allArticles(locale: $locale, first: 3) {
      id
      title
      slug
      inEvidenza
      tag {
        id
        slug
        title
      }
      image {
        id
        responsiveImage(
          imgixParams: { fm: webp, q: "60", fit: crop, w: "1000" }
        ) {
          ...ResponsiveImageFragment
        }
      }
    }
    home(locale: $locale) {
      seo {
        ...SeoFragment
      }
      hero {
        id
        whiteText
        title
        text
        previewVideo
        overlayFullVideo
        image {
          id
          responsiveImage(imgixParams: { auto: format, q: "60", fit: crop }) {
            ...ResponsiveImageFragment
          }
        }
      }
      homeIntroText
      titleExpertise
      preTitleExpertise
      preTitleManifesto
      titleManifesto
      linkPreviewHomeBannerManifesto
      linkPreviewHomeBannerTitleManifesto
      linkPreviewHomeBannerCtaManifesto
      whiteTextHomeBannerManifesto
      preTitleProjects
      titleProjects
      homeProjects {
        fullWidth
        smallHeight
        project {
          ...ProjectListingFragment
        }
      }
      preTitleTechnology
      homeTechnologyTitle
      homeTechnologyList {
        id
        slug
        title
        imagePreviewListing {
          id
          url
        }
      }
      preTitleBlog
      titleBlog
      articles {
        id
        title
        slug
        inEvidenza
        tag {
          id
          slug
          title
        }
        image {
          id
          responsiveImage(
            imgixParams: { fm: webp, q: "60", fit: crop, w: "1000" }
          ) {
            ...ResponsiveImageFragment
          }
        }
      }
      ctaType
      contactBannerBlock {
        id
        title
        videoUrl
        ctaLink
        ctaLabel
      }
    }
  }
`
